<template>
  <base-section
    id="k-d-a-consult-point-card"
    :class="isOddNumber ? 'background-tech-list' : 'white'"
    :space="g_bLowestBr ? 0 : 40"
    :top-inner-shadow="isFirstIndex"
  >
    <v-container :class="`pa-0 my-${g_bLowerBr ? 0 : 10} pb-${g_bLowerBr ? 10 : 0}`">
      <v-row no-gutters>
        <v-col v-if="$vuetify.breakpoint.lgAndUp" lg="2" md="2" />
        <v-col
          cols="12"
          lg="2"
          md="2"
          style="flex-direction: column; display: flex; align-items: center"
        >
          <div class="img-container">
            <img
              :src="imgConsult"
              :class="g_bLowestBr ? 'top' : 'right'"
              style="object-fit: contain"
            />
          </div>
        </v-col>
        <v-col v-if="$vuetify.breakpoint.lgAndUp" lg="2" md="2" />
        <v-col cols="12" lg="5" md="5" :class="`${g_bLowerBr ? '' : 'mx-3'}`" style="margin: auto">
          <div :class="`${g_bLowerBr ? 'px-5' : ''}`">
            <div :class="`kda-ts-${g_bLowerBr ? 28 : 54}pt wt-extrabold`">{{ strConsult }}</div>
            <div
              :class="`kda-ts-${g_bLowerBr ? 12 : 20}pt nunito wt-regular mt-4 mt-md-4 `"
              v-html="strDescription"
            ></div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
import BaseBreakpoint from '@/mixins/base-breakpoint';

export default {
  name: 'KDAConsultPointCard',

  mixins: [BaseBreakpoint],

  props: {
    strConsult: {
      type: String,
      default: ''
    },
    strDescription: {
      type: String,
      default: ''
    },
    numIndex: {
      type: Number,
      default: 0
    },
    imgConsult: {
      type: String,
      default: ''
    }
  },

  computed: {
    isOddNumber() {
      return this.numIndex % 2 === 1;
    },
    isFirstIndex() {
      return this.numIndex === 0;
    }
  }
};
</script>

<style scoped lang="scss">
@import 'src/styles/variables.scss';

.col-reverse {
  display: flex !important;
  flex-direction: column-reverse !important;
}

.row-wrapper {
  display: flex;
  flex-direction: row;
}

.background-tech-list {
  background-color: #fbfbfb;
}

.cursor-pointer {
  cursor: pointer;
}

.img-container {
  overflow: hidden;
  position: relative;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;

  img {
    &.right {
      height: 460px;
      width: 460px;
    }

    &.top {
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      position: relative;
      padding-bottom: 10%;
    }
  }
}
</style>
